import React from 'react'
import {Box} from '@chakra-ui/react'

function AlgebraicGraphDemo() {
    return (
        <Box
            as="iframe"
            h="lg"
            w="full"
            src="https://www.desmos.com/calculator/3jcpgymhgr"
            loading="lazy"
        ></Box>
    )
}

export { AlgebraicGraphDemo }